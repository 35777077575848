import { EditorReadyFn, FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getFrameUrl } from './utils/editor';
import webBiLogger from '@wix/web-bi-logger';
import colorAppComp from './components/colorPicker/editor.controller';
import { editorPluginLoaded } from '@wix/bi-logger-wixlabs-users/v2';
import { AppApi } from './types/appApi';

const TOKEN = 'token';
let isClassicEditor: boolean = true;
type anyArgs = Array<any>;
const debugMessage = (...args: anyArgs) => {
  console.log(...args);
};
export const editorReady: EditorReadyFn = async (
  editorSDK: FlowEditorSDK,
  token,
  { firstInstall },
  flowAPI: FlowAPI,
) => {
  debugMessage(firstInstall ? 'FIRST RUN' : 'RUN');
  await openSidePanel(editorSDK, flowAPI);
};
const openSidePanel = async (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => {
  const logger = webBiLogger.factory().logger();
  const pageId = await editorSdk.info.getAppDefinitionId('');
  const instanceId = await editorSdk.info.getAppInstanceId('');
  const siteId = await editorSdk.info.getSiteId('');
  const metaSiteId = await editorSdk.info.getMetaSiteId('');
  logger.report(
    editorPluginLoaded({
      app_site_id: siteId,
      instance_id: instanceId,
      biToken: metaSiteId,
      page_id: pageId,
      plugin_name: 'color_picker',
    }),
  );

  isClassicEditor = flowAPI.environment.isClassicEditor;

  const toolsMenuItemOptions = {
    title: flowAPI.translations.t('app.title'),
  };
  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.settings.title'),
    width: 240,
    height: 190,
    url: getFrameUrl(colorAppComp.type, 'ColorPicker'),
    initialPosition: { x: 100, y: 100 },
  };
  await editorSdk.editor.registerToolsPanel(
    TOKEN,
    toolsMenuItemOptions,
    toolsPanelOptions,
  );
};
const createPrivateAPI = (): AppApi => {
  return {
    getIsClassicEditor: (): boolean => isClassicEditor,
  };
};

export const exports = () => ({
  private: createPrivateAPI(),
});
